<script setup lang="ts">
import { CmsSectionSidebar, useCmsSection } from "@shopware-pwa/composables-next";
import {useHeader} from "~/composables/useHeader";

const { apiInstance } = useShopwareContext();

const props = defineProps<{
  content: CmsSectionSidebar;
}>();

const { getPositionContent } = useCmsSection(props.content);

const sidebarBlocks = getPositionContent("sidebar");
const mainBlocks = getPositionContent("main");

const { category } = useCategory();
const categoryId = category.value?.id;

const { data: totalCount } = await useAsyncData('totalCount' + categoryId, async () => {
  const response = await apiInstance.invoke.post("/store-api/product-listing/" + categoryId, {
    "total-count-mode": "1",
  });

  return response?.data?.total ?? 0;
});
</script>

<template>
  <div class="s-gutter">
    <div class="c-width-default">
      <ProductCategoryToolbar />

      <div
        class="grid-gap-22 mt-8 lg:mt-8 lg:grid"
        :class="{
          'lg:grid-cols-[5fr]': totalCount == 0,
          'lg:grid-cols-[max(280px)_4fr]': totalCount != 0
        }"
      >
        <aside v-show="totalCount && totalCount > 0" ref="filter" class="hidden lg:block">
          <CmsGenericBlock v-for="cmsBlock in sidebarBlocks" :key="cmsBlock.id" :content="cmsBlock" />
        </aside>
        <div>
          <CmsGenericBlock v-for="cmsBlock in mainBlocks" :key="cmsBlock.id" :content="cmsBlock" />
        </div>
      </div>
    </div>
  </div>
</template>
